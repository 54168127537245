import { getToken, setToken } from '@/utils/auth'

const state = {
  name: '主力监控',
  token: getToken()
}

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_TOKEN: (state, token) => {
    setToken(token)
    state.token = token
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
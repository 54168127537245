<template>
  <div class="search">
    <div class="search-row">
      <van-search class="search-input" v-model="query.key" placeholder="请输入转债名称/代码/拼音"></van-search>
      <!-- <div class="search-row-btn" @click="handleSearch"><span>搜索</span></div> -->
      <!-- <div class="search-row-btn margin-left-20px" @click="handleReset"><span>重置</span></div> -->
      <van-button class="margin-left-20px" color="rgba(244, 244, 244, 1)" size="normal" @click="handleSearch">搜索</van-button>
      <van-button class="margin-left-20px" color="rgba(244, 244, 244, 1)" size="normal" @click="handleReset">重置</van-button>
    </div>
    <el-table :data="tableData" style="width: 100%" @row-click="handleRow">
      <el-table-column fixed prop="bond_name" min-width="100" align="center" label="转债名称">
        <template slot-scope="scope">
          <div class="display">
            <div>
              <div>{{ scope.row.bond_name }}</div>
              <div>{{ scope.row.bond_id }}</div>
            </div>
            <div>
              <div :style="getStyle(scope.row)">{{ scope.row.bk }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="yjl" sortable label="溢价率" min-width="100" align="center" :sort-method="sortPercentage"></el-table-column>
      <el-table-column prop="stock_name" min-width="100" align="center" label="正股名称">
        <template slot-scope="scope">
          <div>{{ scope.row.stock_name }}</div>
          <div>{{ scope.row.stock_id }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sygm" sortable label="剩余规模" min-width="100" align="center"></el-table-column>
      <el-table-column prop="ltgm" sortable label="流通规模" min-width="100" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getBondSearch } from '@/api/home.js';
export default {
  name: 'SearchView',
  data() {
    return {
      tableData: [],
      query: {
        key: null,
      },
    };
  },
  created() {
    if (this.$store.state.user.token == null) {
      this.$router.push({
        path: '/404',
      });
    }
  },
  methods: {
    /* 搜索 */
    handleSearch() {
      this.getList();
    },
    /* 重置 */
    handleReset() {
      this.query.key = null;
      this.getList();
    },
    getList() {
      getBondSearch(this.query).then((res) => {
        this.tableData = res.data;
      });
    },
    /* 点击某行 */
    handleRow(row) {
      window.location.href = row.url;
    },
    /* 钉钉验证 */
    isInDingTalk() {
      return navigator.userAgent.toLowerCase().includes('dingtalk');
    },
    /* 去除百分号排序 */
    sortPercentage(a, b) {
      // 移除百分号，并转换为数字进行比较
      const numA = parseFloat(a.yjl.replace('%', ''));
      const numB = parseFloat(b.yjl.replace('%', ''));
      return numA - numB;
    },
    /* 获取颜色 */
    getStyle(item) {
      let color = 'red';
      switch (item.bk) {
        case '科':
          color = 'orange';
          break;
        case '沪':
          color = 'green';
          break;
        case '创':
          color = 'red';
          break;
        case '深':
          color = 'blue';
          break;
      }
      return {
        color: color,
        border: 'solid 1px ' + color,
        padding: '0 2px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 46.875rem;
  .search-row {
    display: flex;
    font-size: 1.5rem;
    align-content: center;
    align-items: center;
    padding: 0.6375rem;
    .search-input {
      height: 6.25rem;
      width: 60%;
      background-color: #f7f8fa;
    }
    .search-row-btn {
      padding: 0.5rem 0.75rem;
      background-color: rgba(244, 244, 244, 1);
      cursor: pointer;
      width: 25%;
    }
  }
}
.van-search {
  padding: 0;
  font-size: 1.5rem;
  .van-cell {
    padding: 0.5rem 0;
  }
}
.van-button__text {
  color: rgba(0, 0, 0, 1);
}
.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
</style>

<template>
    <div class="home">
        <div class="home-box">
            <img src="@/assets/404.svg" alt="">
            <div class="home-box-message">当前页面已失效</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyView'
}
</script>

<style lang="scss" scoped>
.home {
    .home-box {
        img {
            width: 17.625rem;
            height: 14.125rem;
            margin-top: 30%;
        }
        .home-box-message {
            color: rgba(51, 51, 51, 1);
            font-weight: 400;
            font-size: 1.875rem;
            margin-top: 1.875rem;
        }
    }
}
</style>
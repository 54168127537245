<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '300px',
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      handler() {
        this.setOptions();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions() {
      // console.log('数据处理', this.chartData.length)
      // 数据处理
      let xAxisList = []; // x 轴
      let jlrList = []; // 主力净流入
      let zdjlrList = []; // 含中单净流入
      this.chartData.forEach((element) => {
        xAxisList.push(element.time);
        jlrList.push(this.getNumAnd(element.jlr));
        zdjlrList.push(this.getNumAnd(element.zdjlr));
      });

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['主力净流入(万)', '含中单主力净流入(万)'],
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xAxisList,
            axisLabel: {
              formatter: (value) => {
                return value.substring(0, 5);
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: '主力净流入(万)',
            type: 'line',
            data: jlrList,
          },
          {
            name: '含中单主力净流入(万)',
            type: 'line',
            data: zdjlrList,
          },
        ],
      });
    },
    getNumAnd(value) {
      // return value
      const num = value / 10000;
      return num.toFixed(4);
    },
  },
};
</script>

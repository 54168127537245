<template>
  <div>
    <p v-if="isFromDingTalk">页面来自钉钉</p>
    <p v-else>页面不来自钉钉</p>
  </div>
</template>

<script>
export default {
  name: 'DemoVue',
  data() {
    return {
      isFromDingTalk: false
    }
  },
  created() {
    this.isFromDingTalk = this.isInDingTalk()
  },
  methods: {
    /* 钉钉验证 */
    isInDingTalk() {
      return navigator.userAgent.toLowerCase().includes('dingtalk');
    },
  }
}
</script>
<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '250px'
    },
    barData: {
      type: Array
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    barData: {
      handler: function() {
        this.initChart();
      },
      deep: true
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');

      this.chart.setOption({
        animation: false, // 设置为 false 以关闭动画效果
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 20,
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['特大', '大单', '中单', '小单']
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLabel: {  
              show: false // 不显示 Y 轴标签  
          },
          splitLine: {  
              show: false // 不显示 Y 轴刻度线  
          }
        }],
        series: [{
          name: '',
          type: 'bar',
          // stack: 'vistors',
          barWidth: '40%',
          data: this.barData,
          itemStyle: {
            normal: {
              color: function(params) {  
                // 判断数据值是否为负数，如果是则返回绿色，否则返回红色  
                if (params.data < 0) {  
                    return 'rgba(3, 225, 150, 1)';
                } else {  
                    return 'rgba(237, 61, 61, 1)';  
                }  
              }  
            }
          },
          label: {
            show: true,
            position: 'top',
            // formatter: '{c}'
            formatter: function(params) {
              var value = params.data;  
              if (value > 100000000) {
                const num = value / 100000000;
                return num.toFixed(2) + '亿'
              } else if (value > 10000) {
                const num = value / 10000;
                return num.toFixed(2) + '万'
              } else if (value < -100000000) {
                const num = value / 100000000;
                return num.toFixed(2) + '亿'
              } else if (value < -10000) {
                const num = value / 10000;
                return num.toFixed(2) + '万'
              } else {
                return value
              }
            }
          }
        }]
      });
    }
  }
};
</script>

import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {
    return config;
})
// 响应拦截器
service.interceptors.response.use(res => {
    return res.data;
}, error => {
    return Promise.reject(error);
})

export default service;
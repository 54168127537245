<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '200px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      expectedData: [], 
      xAxisData: []
    };
  },
  watch: {
    chartData: {
      handler() {
        this.setOptions();
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions() {
      let xAxisList = []; // x 轴
      let jlrList = []; // 主力净流入
      let zdjlrList = []; // 含中单净流入
      this.chartData.forEach((element) => {
        xAxisList.push(element.date.substring(5));
        jlrList.push(this.getNumAnd(element.zljlr));
        zdjlrList.push(this.getNumAnd(element.zdzljlr));
      });
      this.chart.setOption({
        animation: false, // 设置为 false 以关闭动画效果
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: xAxisList,
        },
        yAxis: {
          type: 'value',
          // axisLabel: {  
          //     show: false // 不显示 Y 轴标签  
          // },
          // splitLine: {
          //     show: false // 不显示 Y 轴刻度线  
          // }
        },
        legend: {
          data: ['主力净流入(万)', '中单主力净流入(万)'],
        },
        grid: {
          top: 30,
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        series: [{
          name: '主力净流入(万)',
          data: jlrList,
          type: 'line',
        }, {
          name: '中单主力净流入(万)',
          data: zdjlrList,
          type: 'line',
        }]
      });
    },
    getNumAnd(value) {
      const num = value / 10000;
      return num.toFixed(4);
    },
  }
};
</script>

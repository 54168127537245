<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts';
// require('echarts/theme/macarons'); // echarts theme
// import resize from '@/utils/resize';

export default {
  // mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '200px'
    },
    pieData: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  watch: {
    pieData() {
      this.initChart();
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      const title = `${this.getNumAnd(this.pieData)}`
      const color = this.pieData > 0 ? 'rgba(237, 61, 61, 1)' : (this.pieData < 0 ? 'rgba(3, 225, 150, 1)': 'rgba(128, 131, 134, 1)')
      this.chart.setOption({
        title: {
          text: title,
          left: 'center',
          top: 'center',
          textStyle: {
            color: color,
            fontSize: 16
          },
          subtext: '主力净流入',
          subtextStyle: {
            color: 'rgba(128, 131, 134, 1)',
            fontSize: 12
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'], 
            label: {  
                show: false,  
                position: 'center'  
            },
            emphasis: {
              scale: false // 取消鼠标悬浮时的放大效果
            },
            data: [  
                {value: Math.abs(this.pieData)},
                {value: Math.abs(this.pieData)}
            ],
            itemStyle: {
              normal: {
                borderColor: 'rgba(128, 131, 134, 0.2)', // 设置边框颜色为灰色  
                borderWidth: 1, // 设置边框宽度为 1px  
                borderType: 'solid', // 设置边框类型为实线  
                color: (params) => {
                  var colorList = []; // 自定义颜色列表 
                  if (this.pieData > 0) {
                    colorList = ['rgba(237, 61, 61, 1)', 'rgba(255, 255, 255, 1)']; // 自定义颜色列表 
                  } else if (this.pieData == 0) {
                    colorList = ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)']; // 自定义颜色列表 
                  } else {
                    colorList = ['rgba(255, 255, 255, 1)', 'rgba(3, 225, 150, 1)']; // 自定义颜色列表 
                  }
                  return colorList[params.dataIndex]; // 根据数据项的索引返回对应的颜色  
                } 
              }
            }
          }
        ]
      });
    },
    getNumAnd(value) {
      if (value > 100000000) {
        const num = value / 100000000;
        return num.toFixed(2) + '亿'
      } else if (value > 10000) {
        const num = value / 10000;
        return num.toFixed(2) + '万'
      } else if (value < -100000000) {
        const num = value / 100000000;
        return num.toFixed(2) + '亿'
      } else if (value < -10000) {
        const num = value / 10000;
        return num.toFixed(2) + '万'
      } else {
        return value + '元'
      }
    }
  }
};
</script>

import request from "@/utils/request";

// 获取详情接口
export function getBondData(query) {
    return request({
        url: 'api/bond/data',
        method: 'get',
        params: query
    })
}

// 定时更新请求接口
export function getBondRepress(query) {
    return request({
        url: 'api/bond/repress',
        method: 'get',
        params: query
    })
}

// 新版定时更新请求接口，速度更快
export function getBondNasRepress(query) {
    return request({
        url: 'api/bond/nas/repress',
        method: 'get',
        params: query
    })
}

// 获取已采集好的资金列表
export function getZjlrList(query) {
    return request({
        url: 'api/bond/nas/zjlrList',
        method: 'get',
        params: query
    })
}

// 查询可转债列表
export function getBondSearch(query) {
    return request({
        url: 'api/bond/search',
        method: 'get',
        params: query
    })
}

// 获取请求客户端ip
export function getUserIp(query) {
    return request({
        url: 'api/bond/getip',
        method: 'get',
        params: query
    })
}
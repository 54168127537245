<template>
  <div class="home">
    <div v-if="flag" class="head">
      <div class="head-title-name">{{ bondData.name }}</div>
      <div class="head-title-num" :class="bondData.zdf > 0 ? 'color-red' : bondData.zdf == 0 ? 'color-grey' : 'color-green'">
        <span> {{ bondData.price ? bondData.price.toFixed(3) : 0.0 }} </span>
        <span> {{ bondData.zdf > 0 ? '+' : '-' }} {{ bondData.zdf }}% </span>
        <span> ({{ bondData.zde ? bondData.zde.toFixed(3) : 0.0 }}) </span>
      </div>
      <div class="head-title-table">
        <div>
          <div>
            <span class="head-title-table-row-col-name">最&nbsp;&nbsp;&nbsp;&nbsp;高</span>
            <span class="head-title-table-row-col-data" :class="bondData.hight > bondData.zrspj ? 'color-red' : bondData.hight < bondData.zrspj ? 'color-green' : 'color-grey'">
              {{ bondData.hight ? bondData.hight.toFixed(3) : 0.0 }}
            </span>
          </div>
          <div>
            <span class="head-title-table-row-col-name">最&nbsp;&nbsp;&nbsp;&nbsp;低</span>
            <span class="head-title-table-row-col-data" :class="bondData.low > bondData.zrspj ? 'color-red' : bondData.low < bondData.zrspj ? 'color-green' : 'color-grey'">
              {{ bondData.low ? bondData.low.toFixed(3) : 0.0 }}
            </span>
          </div>
        </div>
        <div>
          <div>
            <span class="head-title-table-row-col-name">今&nbsp;&nbsp;&nbsp;&nbsp;开</span>
            <span class="head-title-table-row-col-data" :class="bondData.open > bondData.zrspj ? 'color-red' : bondData.open < bondData.zrspj ? 'color-green' : 'color-grey'">
              {{ bondData.open ? bondData.open.toFixed(3) : 0.0 }}
            </span>
          </div>
          <div>
            <span class="head-title-table-row-col-name">成交额</span>
            <span class="head-title-table-row-col-data">{{ bondData.cje | getNumAnd }}</span>
          </div>
        </div>
        <div>
          <div>
            <span class="head-title-table-row-col-name">振&nbsp;&nbsp;&nbsp;&nbsp;幅</span>
            <span class="head-title-table-row-col-data">{{ bondData.zhenfu }}%</span>
          </div>
          <div>
            <span class="head-title-table-row-col-name">量&nbsp;&nbsp;&nbsp;&nbsp;比</span>
            <span class="head-title-table-row-col-data">{{ bondData.lbi }}</span>
          </div>
        </div>
      </div>
      <div class="head-title-type">
        <div>
          <span>{{ bondData.zg_name }}: </span>
          <span :class="bondData.zg_zdf > 0 ? 'color-red' : bondData.zg_zdf == 0 ? 'color-grey' : 'color-green'">{{ bondData.zg_price ? bondData.zg_price.toFixed(2) : 0.0 }} </span>
          <span :class="bondData.zg_zdf > 0 ? 'color-red' : bondData.zg_zdf == 0 ? 'color-grey' : 'color-green'"> {{ bondData.zg_zdf }}%</span>
        </div>
        <div>
          <span>溢价率：</span><span>{{ bondData.yjl }}%</span>
        </div>
      </div>
    </div>
    <div v-if="flag" class="base">
      <div class="base-title">基本信息</div>
      <div class="base-info">
        <div class="base-info-row">
          <div class="base-info-col">
            <span class="base-info-col-title">债券代码</span>
            <span class="base-info-col-data">{{ bondData.bond_id }}</span>
          </div>
          <div class="base-info-col">
            <span class="base-info-col-title">债券简称</span>
            <span class="base-info-col-data">{{ bondData.name }}</span>
          </div>
          <div class="base-info-col">
            <span class="base-info-col-title">最新债券评级</span>
            <span class="base-info-col-data">{{ bondData.pingji }}</span>
          </div>
          <div class="base-info-col">
            <span class="base-info-col-title">到期日期</span>
            <span class="base-info-col-data">{{ bondData.dqrq }}</span>
          </div>
        </div>
        <div class="base-info-row">
          <div class="base-info-col">
            <span class="base-info-col-title">当前余额</span>
            <span class="base-info-col-data">{{ bondData.dqye | getNumTwn }}</span>
          </div>
          <div class="base-info-col">
            <span class="base-info-col-title">流通规模</span>
            <span class="base-info-col-data">{{ bondData.ltgm | getNumTwn }}</span>
          </div>
          <div class="base-info-col">
            <span class="base-info-col-title">正股名称</span>
            <span class="base-info-col-data">{{ bondData.zg_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="flag" class="concept">
      <div class="concept-title">所属概念</div>
      <div class="concept-box">
        <div v-for="(item, index) in conceptList" :key="index" class="concept-box-item" :class="item.IS_PRECISE == 1 ? 'left-box-bolder' : ''">
          <div class="item-center">
            <span class="item-center-name">{{ item.BOARD_NAME }}</span>
            <span class="item-center-yield" :class="item.BOARD_YIELD > 0 ? 'color-red' : item.BOARD_YIELD == 0 ? 'color-grey' : 'color-green'">{{ item.BOARD_YIELD }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="flag" class="flow">
      <div class="flow-title">今日资金流向</div>
      <PieChart :pieData="bondData.zljlr"></PieChart>
      <div class="zljlr-diff">
        <span v-if="zljlrLog[0] != zljlrLog[1]" :class="zljlrLog[0] - zljlrLog[1] > 0 ? 'color-red' : 'color-green'">{{ zljlrDiff }}</span>
      </div>
      <div class="flow-foot">
        <span>含中单主力净流入: </span>
        <span :class="bondData.zljlr + bondData.zdjlr > 0 ? 'color-red' : bondData.zljlr + bondData.zdjlr == 0 ? 'color-grey' : 'color-green'">
          {{ (bondData.zljlr + bondData.zdjlr) | getNumAnd }}
        </span>
      </div>
      <BarChart :barData="barData" class="flow-bar"></BarChart>
    </div>
    <div class="today">
      <div v-if="flag" class="today-title">今日资金走势图</div>
      <div class="today-box">
        <LinesChart :chartData="tadayList" class="today-box-line"></LinesChart>
      </div>
    </div>
    <div v-if="flag" class="more">
      <div class="more-title">
        <div>多日资金变动（万元）</div>
        <!-- <div>
          <img src="@/assets/more.svg" alt="">
        </div> -->
      </div>
      <LineChart :chartData="daysZllrList"></LineChart>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/chart/BarChart';
import LineChart from '@/components/chart/LineChart';
import LinesChart from '@/components/chart/LinesChart';
import PieChart from '@/components/chart/PieChart';
import { getBondData, getBondNasRepress, getZjlrList } from '@/api/home.js';
import taday_data from './taday_data.json';
import taday_date_old from './tady_data_old.json';
export default {
  name: 'HomeViewDemo',
  components: { BarChart, LineChart, PieChart, LinesChart },
  data() {
    return {
      bondData: {},
      barData: [],
      chartData: {
        expectedData: [],
        xAxisData: [],
      },
      newExpectedData: [],
      // 查询条件
      query: {
        e: null,
      },
      timer: null, // 定时器
      zljlrLog: [],
      zljlrDiff: null,
      tadayList: [], // 今日资金走势
      conceptList: [], // 所属概念列表
      daysZllrList: [], // 多日资金变动
      checkUpdateTime: null,
      taday_data: taday_data,
      taday_date_old: taday_date_old,
      outTimer: null, // 离开后定时器
      outNum: 0, // 心跳计时
      outBack: 30, // 心跳时间
      flag: true,
    };
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  created() {
    if (this.$route.query.e) {
      this.query.e = this.$route.query.e;
      this.getList();
    } else {
      this.$router.push({
        path: '/404',
      });
    }
  },
  methods: {
    getList() {
      getBondData(this.query)
        .then((res) => {
          if (res.code === 200) {
            const data = JSON.parse(JSON.stringify(res.data));
            this.bondData = JSON.parse(JSON.stringify(data));
            document.title = '主力监控: ' + data.name;
            this.serviceConcept(data.concept_data); // 所属概念
            // this.tadayList = data.today_data.map((item) => {
            //   item.time = this.parseTime(item.time, "{h}:{i}:{s}");
            //   return item;
            // }); // 今日资金走势
            this.barData = [];
            this.barData.push(data.cddjlr); // 超大
            this.barData.push(data.ddjlr); // 大单
            this.barData.push(data.zdjlr); // 中单
            this.barData.push(data.xdjlr); // 小单
            this.daysZllrList = data.days_zllr; // 多日资金变动
            this.updateTime(); // 启动定时器
          } else {
            this.$message.error(res.message);
            this.$router.push({
              path: '/404',
            });
          }
        })
        .then(() => {
          this.getZjlrListHandle();
        })
        .catch((e) => {
          console.error(e);
          this.$router.push({
            path: '/404',
          });
        });
    },
    /* 定时请求接口 */
    getRepressService() {
      getBondNasRepress(this.query).then((res) => {
        const dataItem = JSON.parse(JSON.stringify(res.data));
        this.barData = [];
        this.checkUpdateTime = this.parseTime(dataItem.update_time, '{h}:{i}:{s}'); // 资金流入更新时间
        this.serviceConcept(dataItem.concept_data); // 所属概念
        /**
         * update_time 大于最后一个走势图时间
         * 将最后这个跟新时间和主力净流入和中单净流入（主力净流入+中单净流入）作为新数据加入到走势图
         */
        let lastTaday = this.tadayList[this.tadayList.length - 1];
        let updateTime = this.checkUpdateTime;
        let lastTime = lastTaday.time;
        // console.log(dataItem.zljlr, ' + ', dataItem.zdjlr, ' == ', dataItem.zljlr + dataItem.zdjlr)
        if (updateTime > lastTime) {
          let item = {
            time: updateTime,
            jlr: dataItem.zljlr,
            zdjlr: dataItem.zljlr + dataItem.zdjlr,
          };
          this.tadayList.push(item);
        } else {
          // console.log('小于')
        }
        this.barData.push(dataItem.cddjlr); // 超大
        this.barData.push(dataItem.ddjlr); // 大单
        this.barData.push(dataItem.zdjlr); // 中单
        this.barData.push(dataItem.xdjlr); // 小单
        this.bondData.bond_id = dataItem.bond_id; // 债券代码
        this.bondData.price = dataItem.price; // 价格
        this.bondData.zde = dataItem.zde;
        this.bondData.zdf = dataItem.zdf;
        this.bondData.yjl = dataItem.yjl; // 溢价率
        this.bondData.zljlr = dataItem.zljlr; // 主力净流入
        this.bondData.cddjlr = dataItem.cddjlr; // 超大单净流入
        this.bondData.ddjlr = dataItem.ddjlr; // 大单净流入
        this.bondData.zdjlr = dataItem.zdjlr; // 中单净流入
        this.bondData.xdjlr = dataItem.xdjlr; // 小单净流入
        this.bondData.hight = dataItem.hight; // 最高
        this.bondData.low = dataItem.low; // 最低
        this.bondData.zhenfu = dataItem.zhenfu; // 振幅
        this.bondData.cje = dataItem.cje; // 成交额
        this.bondData.lbi = dataItem.lbi; // 量比
        this.bondData.zg_price = dataItem.zg_price; //
        this.bondData.zg_zdf = dataItem.zg_zdf; //
        this.bondData.update_time = dataItem.update_time; //
        /* 多日资金变动绘图最后一个数据 */
        let item = this.daysZllrList[this.daysZllrList.length - 1];
        item.zljlr = dataItem.zljlr + this.daysZllrList[this.daysZllrList.length - 2].zljlr;
        this.daysZllrList.splice(this.daysZllrList.length - 1, 1, item);
        // 主力净流入
        this.zljlrLog.push(dataItem.zljlr);
        // this.zljlrLog.push(Math.random() * 100000 + 1)
        if (this.zljlrLog.length >= 3) {
          this.zljlrLog.shift();
        }
        if (this.zljlrLog.length == 2) {
          this.zljlrDiff = this.$options.filters['getNumAnd'](this.zljlrLog[0] - this.zljlrLog[1]);
        } else {
          this.zljlrDiff = null;
        }
      });
    },
    isBetweenTime(startTime, endTime) {
      // 将传入的时间字符串转换为Date对象
      const start = new Date();
      start.setHours(startTime.split(':')[0], startTime.split(':')[1], startTime.split(':')[2], 0);

      const end = new Date();
      end.setHours(endTime.split(':')[0], endTime.split(':')[1], endTime.split(':')[2], 0);

      // 获取当前时间
      const now = new Date();

      // 比较当前时间是否在开始时间和结束时间之间
      return now >= start && now <= end;
    },
    // 判断定时器是否启动
    checkTimeFlag() {
      // return true;
      // const now = new Date(); // 获取当前时间
      // const hour = now.getHours(); // 获取小时
      // const minute = now.getMinutes(); // 获取分钟
      // let isBetweenTimes = false;
      // // 检查时间是否在早上9:15:00 到 11:30:00和下午12:00:00 到15:00:00之间
      // if (((hour == 9 && minute >= 15) || hour >= 10) && hour < 15) {
      //   if ((hour == 11 && minute >= 30) || hour == 12) {
      //     isBetweenTimes = false;
      //   } else {
      //     isBetweenTimes = true;
      //   }
      // }
      // return isBetweenTimes;
      return this.isBetweenTime('09:15:00', '11:30:00') || this.isBetweenTime('12:00:00', '15:00:10')
    },
    // 定时任务
    updateTime() {
      if (this.timer) return;
      if (this.checkTimeFlag()) {
        this.timer = setInterval(() => {
          this.getRepressService();
        }, 3000);
      }
    },
    handleVisibilityChange(event) {
      if (document.hidden || event.target.webkitHidden) {
        // 页面已经关闭了
        clearInterval(this.timer);
        this.timer = null;

        this.outTimer = setInterval(() => {
          this.outNum = this.outNum + 1;
          // console.log(this.outNum);
          if (this.outNum >= this.outBack) {
            clearInterval(this.outTimer);
            this.outTimer = null;
            this.$router.push({
              path: '/404',
            });
            document.title = '404';
          }
        }, 1000);
        // 这里可以写入其他相应的业务逻辑
      } else {
        // 页面重新打开了
        this.getList();
        // 这里可以写入其他相应的业务逻辑
        clearInterval(this.outTimer);
        this.outTimer = null;
        this.outNum = 0;
      }
    },
    // 所属概念数据处理
    serviceConcept(conceptList) {
      if (conceptList && conceptList.length > 0) {
        conceptList.sort((a, b) => b.BOARD_YIELD - a.BOARD_YIELD); // 排序降序
      }
      this.conceptList = [];
      conceptList.forEach((item) => {
        // 带涨停,连板这两个字的前面加个正股
        if (item.BOARD_NAME.includes('涨停') || item.BOARD_NAME.includes('连板')) {
          item.BOARD_NAME = '正股-' + item.BOARD_NAME;
        }
        // 融资融券   转债标的   这两个过滤掉
        if (!item.BOARD_NAME.includes('融资融券') && !item.BOARD_NAME.includes('转债标的')) {
          this.conceptList.push(item);
        }
      });
    },
    // 日期格式化
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null;
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
      let date;
      if (typeof time === 'object') {
        date = time;
      } else {
        if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
          time = parseInt(time);
        } else if (typeof time === 'string') {
          time = time
            .replace(new RegExp(/-/gm), '/')
            .replace('T', ' ')
            .replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if (typeof time === 'number' && time.toString().length === 10) {
          time = time * 1000;
        }
        date = new Date(time);
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
      };
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value;
        }
        return value || 0;
      });
      return time_str;
    },
    getZjlrListHandle() {
      getZjlrList(this.query).then((res) => {
        // console.log("list:", res)
        if (res.code == 200) {
          let list = res.data;
          let tadayListDemo = [];
          list.forEach((params) => {
            let element = JSON.parse(params);
            let item = {
              time: this.parseTime(element.f124, '{h}:{i}:{s}'),
              jlr: element.f62,
              zdjlr: element.f62 + element.f78,
            };
            tadayListDemo.push(item);
          });
          this.tadayList = tadayListDemo;
        }
      });
    },
  },
  filters: {
    getNumAnd(value) {
      if (value > 100000000) {
        const num = value / 100000000;
        return num.toFixed(3) + '亿';
      } else if (value > 10000) {
        const num = value / 10000;
        return num.toFixed(2) + '万';
      } else if (value < -100000000) {
        const num = value / 100000000;
        return num.toFixed(3) + '亿';
      } else if (value < -10000) {
        const num = value / 10000;
        return num.toFixed(2) + '万';
      } else {
        return value + '元';
      }
    },
    getNumTwn(value) {
      if (value > 1) {
        return value.toFixed(3) + '亿';
      }
      return value * 10000 + '万';
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
};
</script>

<style lang="scss" scoped>
.home {
  box-sizing: border-box;
  border: solid 0.0625rem #d9d9d9;
  width: 46.875rem;
  font-size: 1.5rem;
  .head {
    background-color: #f6f7fb;
    .head-title-name {
      color: #242424;
      font-size: 2.25rem;
      font-weight: 500;
    }
    .head-title-num {
      font-size: 1.5rem;
      font-weight: 500;
    }
    .head-title-table {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 1.5625rem 1.25rem;
      border-bottom: solid 0.0625rem #d9d9d9;
      .head-title-table-row-col-name {
        width: 4.5rem;
      }
      .head-title-table-row-col-data {
        padding-left: 0.3125rem;
      }
    }
    .head-title-type {
      display: flex;
      justify-content: space-between;
      font-size: 1.375rem;
      padding: 0.9375rem 1.25rem;
    }
  }
  .flow {
    padding: 1.25rem 1.875rem;
    position: relative;
    .flow-title {
      text-align: left;
      font-size: 1.875rem;
      // color: rgba(128, 131, 134, 1);
      color: rgba(36, 36, 36, 1);
      font-weight: bold;
    }
    .flow-foot {
      font-size: 1.875rem;
    }
    .zljlr-diff {
      position: absolute;
      top: 7.5rem;
      left: 22rem;
      font-size: 1rem;
    }
    .flow-bar {
      margin-top: 1.5rem;
    }
  }
  .base {
    padding: 0 1.875rem;
    margin-top: 1.75rem;
    .base-title {
      text-align: left;
      font-size: 1.875rem;
      color: rgba(36, 36, 36, 1);
      font-weight: bold;
      margin-bottom: 0.625rem;
    }
    .base-info {
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      text-align: left;
      padding: 1.875rem 0;
      border-top: solid 0.0625rem #d9d9d9;
      .base-info-row {
        width: 21.5625rem;
        .base-info-col {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .base-info-col-title {
            width: 9rem;
            font-size: 1.5rem;
            font-weight: 400;
            color: rgba(128, 131, 134, 1);
          }
          .base-info-col-data {
            font-size: 1.5rem;
            font-weight: 500;
            color: rgba(36, 36, 36, 1);
          }
        }
      }
      .base-info-row:nth-child(1) {
        padding-right: 1.25rem;
        border-right: solid 0.0625rem #d9d9d9;
      }
      .base-info-row:nth-child(2) {
        padding-left: 1.25rem;
      }
    }
  }
  .concept {
    padding: 0 1.875rem;
    .concept-title {
      text-align: left;
      font-size: 1.875rem;
      color: rgba(36, 36, 36, 1);
      font-weight: bold;
      margin-bottom: 0.625rem;
    }
    .concept-box {
      display: flex;
      // justify-content: space-between;
      font-size: 1.5rem;
      text-align: left;
      padding: 1.875rem 0;
      border-top: solid 0.0625rem #d9d9d9;
      flex-wrap: wrap;
      .concept-box-item {
        border: 1px solid rgba(0, 122, 255, 1);
        padding: 0.625rem;
        font-size: 1.5rem;
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
        border-radius: 0.5rem;
        .item-center {
          .item-center-name {
            font-size: 1.5rem;
            font-weight: 400;
            color: rgba(36, 36, 36, 1);
          }
          .item-center-yield {
            font-size: 1.5rem;
            font-weight: 400;
            margin-left: 0.625rem;
          }
        }
      }
      .left-box-bolder {
        border-left: 3px solid rgba(0, 122, 255, 1);
      }
    }
  }
  .today {
    padding: 0 1.875rem;
    margin-top: 3.75rem;
    .today-title {
      text-align: left;
      font-size: 1.875rem;
      color: rgba(36, 36, 36, 1);
      font-weight: bold;
      margin-bottom: 0.625rem;
    }
    .today-box {
      border-top: solid 0.0625rem #d9d9d9;
      .today-box-line {
        margin-top: 1.5rem;
      }
    }
  }
  .more {
    border-top: solid 1.25rem rgba(251, 251, 251, 1);
    margin-top: 1.25rem;
    padding: 1.875rem;
    padding-bottom: 7.5rem;
    .more-title {
      text-align: left;
      font-size: 1.875rem;
      font-weight: 400;
      color: rgba(36, 36, 36, 1);
      margin-bottom: 1.5625rem;
      display: flex;
      align-items: center;
    }
  }
}
</style>

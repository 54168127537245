<template>
  <div></div>
</template>

<script>
import { removeToken } from '@/utils/auth';
import { getUserIp } from '@/api/home.js';
import md5 from 'js-md5';
export default {
  data() {
    return {};
  },
  created() {
    getUserIp()
      .then((res) => {
        if (res.code == 200) {
          let token = md5(res.data.ip);
          this.$store.commit('user/SET_TOKEN', token);
          this.$router.push({
            path: '/search'
          })
        }
      })
      .catch(() => {
        removeToken();
      });
  },
};
</script>
